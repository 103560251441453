(function($) {

    var timeout1 = false;
    var timeout2 = false;

    var openCanvas = function(){

        clearTimeout(timeout2);
        $('.navbar-top').addClass('open-nav');
        $('.navbar-offcanvas').addClass('open-1');
        $('body').addClass('open-canvas-pre');
        
        timeout1 = setTimeout(function(){
          $('body').addClass('open-canvas');
        }, 50);

        $('.navbar-toggler').removeClass('collapsed');

    };

    var closeCanvas = function(){

        clearTimeout(timeout1);
        $('.navbar-offcanvas').removeClass('open-1');
        $('.navbar-top').removeClass('open-nav');
        $('.navbar-toggler').addClass('collapsed');
        $('body').removeClass('open-canvas-pre');
        
        timeout2 = setTimeout(function(){
          $('body').removeClass('open-canvas');
        }, 200);
 
    };

    // Open offcanvas menu on mobile
    $('.canvas-open-toggle').click( function(event){

      event.preventDefault();

      if ($(this).hasClass('collapsed')){

        openCanvas();

      } else {

        closeCanvas();

      }

    });

    //Open sub menu 
    $('.sub-menu-toggle').click(function() {
     if ($(this).hasClass('active')){

        var parent = $(this);

        $(this).text('+');

        $(this).next('.sub-menu').slideUp();
        $(this).removeClass('active'); 
        $(this).parent().removeClass('open-sub-menu');          

      } else {

        $(this).text('-');

        $(this).next('.sub-menu').slideDown();
        $(this).addClass('active'); 
        $(this).parent().addClass('open-sub-menu');       
      }
    });

    $(document).on('click','body.open-canvas', function(event) { 
        if(!$(event.target).closest('#navbarNav').length && !$(event.target).closest('.navbar-toggler').length) {
          closeCanvas();
        }       
    });

    $(document).on('touchend','body.open-canvas', function(event) { 
        if(!$(event.target).closest('#navbarNav').length && !$(event.target).closest('.navbar-toggler').length) {
          closeCanvas();
        }       
    });

    $(document).on('touchmove','body.open-canvas', function(event) {
        if(!$(event.target).closest('#navbarNav').length && !$(event.target).closest('.navbar-toggler').length) {
          event.preventDefault();
        }  
    }); //
        

})(jQuery);