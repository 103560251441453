(function($) {

// global var
var map = null;
/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	//create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
		icon		: new google.maps.MarkerImage(maps.siteurl + '/wp-content/themes/kruijt/dist/images/kruijt-icon.png', null, null, null, new google.maps.Size(76,100)),
	});


	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			//infowindow.open( map, marker );

		});
		//infowindow.open( map, marker );
	}


}



/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length === 1 )
	{
		// set center of map
		//offsetCenter(latlng, 10, 10);
	    map.setCenter( bounds.getCenter() );
	    
	    map.setZoom( 13 );

	    //map.panBy(0,-70);

	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}


/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {
	
	// var
	var $markers = $el.find('.marker');
	
	
	// vars
	var args = {
		zoom		: 40,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP,
		mapTypeControl: false,
		scrollwheel: false,
		styles: [
		    {
		        "featureType": "landscape",
		        "stylers": [
		            {
		                "hue": "#FFBB00"
		            },
		            {
		                "saturation": 43.400000000000006
		            },
		            {
		                "lightness": 37.599999999999994
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    },
		    {
		        "featureType": "road.highway",
		        "stylers": [
		            {
		                "hue": "#FFC200"
		            },
		            {
		                "saturation": -61.8
		            },
		            {
		                "lightness": 45.599999999999994
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    },
		    {
		        "featureType": "road.arterial",
		        "stylers": [
		            {
		                "hue": "#FF0300"
		            },
		            {
		                "saturation": -100
		            },
		            {
		                "lightness": 51.19999999999999
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    },
		    {
		        "featureType": "road.local",
		        "stylers": [
		            {
		                "hue": "#FF0300"
		            },
		            {
		                "saturation": -100
		            },
		            {
		                "lightness": 52
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    },
		    {
		        "featureType": "water",
		        "stylers": [
		            {
		                "hue": "#0078FF"
		            },
		            {
		                "saturation": -13.200000000000003
		            },
		            {
		                "lightness": 2.4000000000000057
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    },
		    {
		        "featureType": "poi",
		        "stylers": [
		            {
		                "hue": "#00FF6A"
		            },
		            {
		                "saturation": -1.0989010989011234
		            },
		            {
		                "lightness": 11.200000000000017
		            },
		            {
		                "gamma": 1
		            }
		        ]
		    }
		]
	};
	
	
	// create map	        	
	var map = new google.maps.Map( $el[0], args);
	
	
	// add a markers reference
	map.markers = [];
	
	
	// add markers
	$markers.each(function(){
		
    	add_marker( $(this), map );
		
	});
	
	
	// center map
	center_map( map );
	
	
	// return
	return map;
	
}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/

$(document).ready(function(){

	$('.google-map').each(function(){

		// create map
		map = new_map( $(this) );	

	});

});

})(jQuery);