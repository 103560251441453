(function($) {

    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
       
    $(window).scroll(function(){
        if ($(window).scrollTop() > 300){
          $('.navbar-top').addClass('navbar-top-fixed-1'); //
        } else {
          $('.navbar-top').removeClass('navbar-top-fixed-1');
        }

        if ($(window).scrollTop() > 360){
          $('.navbar-top').addClass('navbar-top-fixed-2');
        } else {
          $('.navbar-top').removeClass('navbar-top-fixed-2');
        }
    });     

})(jQuery);